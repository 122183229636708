import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { courses } from "../../../helper/data.helper";
import { useStoreState } from 'easy-peasy';
import toast from 'react-hot-toast';
import { initCourse } from '../../../../helper/api.helper';
import Loader from '../../../../components/loader/Loader';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const Courses = ({ courses = [] }) => {
  const [isLoading, setLoading] = useState(false);
  const [showAllCourses, setShowAllCourses] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 780);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 780);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const user = useStoreState((state) => state.user);
  const navigate = useNavigate();
  const startCourse = (courseId) => {
    setLoading(true);
    if (user) {
      initCourse(courseId)
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'success') {
            setLoading(false);

            navigate(`/course/${res.data.courseActivity}`);
          } else {
            setLoading(false);

            toast.error(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);

          toast.error(err.message);
        });
    } else {
      setLoading(false);

      toast.error('Please login to access courses');
      navigate('/login');
    }
  };

  const displayedCourses = showAllCourses ? courses : courses.slice(0, 3);

  return (
    <div className="margin-top--xl">
      {isLoading ? <Loader /> : null}
      <h2 style={{ fontWeight: 'normal' }}>SHORT COURSES</h2>
      <h2 className="margin-top--xs">Boost Your Well-being with Self-Paced Courses</h2>
      <p>
        Explore our range of self-paced courses for mental health and personal growth. Led by
        experts, these offerings provide practical tools for skill-building, stress management and
        overall well-being. Earn certificates and gain valuable insights at your own pace.
      </p>

      <div className="carousel-container">
        {isMobile && (
          <Swiper
            modules={[Pagination]}
            pagination={{
              dynamicBullets: true,
              clickable: true
            }}
            spaceBetween={50}
            slidesPerView={1}>
            {courses?.map((course) => (
              <SwiperSlide key={course.id}>
                <div
                  key={course.id}
                  className="carousel-card margin-top--lg"
                  onClick={() => startCourse(course.id)}>
                  {/* <Link
              to={`/homepage/course/${course.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            > */}
                  <div className="image-div">
                    <img alt="course-pic" src={course.imageUrl} height={150} />
                  </div>
                  <div className="course-details">
                    <h3 style={{ marginTop: '-6px' }}>{course.name}</h3>
                    <ul>
                      {course.desc.map((section) =>
                        section.type === 'points'
                          ? section.text.map((text) => <li key={text}>{text}</li>)
                          : []
                      )}
                    </ul>
                    <div className="flex-container justify-between">
                      <div className="flex-container">
                        {course.instructors.map((instructor) => (
                          <img
                            title={instructor.name}
                            key={instructor.name}
                            src={instructor.picture}
                            height={35}
                            alt={instructor.name}
                          />
                        ))}
                      </div>
                      <b className="duration-tag">{course.duration}</b>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>

      <div className="flex-container  list-div">
        {!isMobile &&
          displayedCourses?.map((course) => (
            <div
              key={course.id}
              className="course-div margin-top--lg"
              onClick={() => startCourse(course.id)}>
              {/* <Link
              to={`/homepage/course/${course.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            > */}
              <div className="image-div">
                <img alt="course-pic" src={course.imageUrl} height={150} />
              </div>
              <div className="course-details">
                <h3 style={{ marginTop: '-6px' }}>{course.name}</h3>
                <ul>
                  {course.desc.map((section) =>
                    section.type === 'points'
                      ? section.text.map((text) => <li key={text}>{text}</li>)
                      : []
                  )}
                </ul>
                <div className="flex-container justify-between">
                  <div className="flex-container">
                    {course.instructors.map((instructor) => (
                      <img
                        title={instructor.name}
                        key={instructor.name}
                        src={instructor.picture}
                        width={35}
                        height={35}
                        alt={instructor.name}
                      />
                    ))}
                  </div>
                  <b className="duration-tag">{course.duration}</b>
                </div>
              </div>
              {/* </Link> */}
            </div>
          ))}
      </div>

      {
        !isMobile && courses && courses.length > 3 && (
          //   <Link
          //   to={`course-list`}
          //   style={{ textDecoration: "none", color: "inherit" }}
          // >
          <Button
            variant="contained"
            color="primary"
            // size="small"
            sx={{ textTransform: 'none', marginTop: '20px' }}
            onClick={() => setShowAllCourses((prev) => !prev)}>
            {showAllCourses ? 'Show less' : 'Explore more courses'}
          </Button>
        )
        //  </Link>
      }

      <style jsx>{`
        .list-div {
          flex-wrap: wrap;
          justify-content: left;
        }
        .course-div {
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          min-width: 280px;
          width: 320px;
          overflow: hidden;
          margin-right: 5%;
        }
        .course-div:hover {
          background-color: #f4f4f4;
        }
        .carousel-card {
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          min-width: 280px;
          width: 320px;
          overflow: hidden;
          // margin-right: 2%;
        }
        .carousel-card:hover {
          background-color: #f4f4f4;
        }
        .carousel-container {
          max-width: 322px;
          margin: auto;
        }
        .image-div img {
          object-fit: cover;
        }
        .course-details {
          padding: 20px;
        }
        ul {
          padding-left: 17px;
          line-height: 25px;
        }
        .course-details img {
          border-radius: 50%;
          margin-left: 5px;
          width: unset !important;
        }
        .duration-tag {
          border-radius: 5px;
          padding: 5px 8px;
          background-color: rgb(234 234 234);
        }
        @media only screen and (max-width: 1025px) {
          .list-div {
            justify-content: left;
          }
          .course-div {
            margin-right: 5%;
          }
        }
      `}</style>
    </div>
  );
};

export default Courses;
