import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PdfPage from './PdfPage';
import QuizPage from './QuizPage';
import { useNavigate } from 'react-router-dom';

import CourseNavigation from './CourseNavigation';
import Assessment from './Assessment';
import Assignment from './Assignment';
import { getCourseActivity } from '../../../../../helper/api.helper';
import toast from 'react-hot-toast';
import Loader from '../../../../../components/loader/Loader';

const Course = () => {
  const { id } = useParams();

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [activeComponent, setActiveComponent] = useState('assessment');
  const navigate = useNavigate();
  const [visitedPages, setVisitedPages] = useState(() => {
    const saved = localStorage.getItem(`visitedPages${id}`);
    return saved ? JSON.parse(saved) : {};
  });

  useEffect(() => {
    localStorage.setItem(`visitedPages${id}`, JSON.stringify(visitedPages));
  }, [visitedPages, id]);

  useEffect(() => {
    getCourseActivity(id)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          setSelectedCourse(res.data);
          if (res.data.courseActivity.assignment_url) {
            setVisitedPages((prevState) => ({
              ...prevState,
              assignment: true
            }));
          }
        } else {
          toast.error(res.message);
          navigate('/');
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
    // const foundCourse = courses.find((course) => course.id === parseInt(id));
    // setSelectedCourse(foundCourse);
  }, [id]);

  // if (!selectedCourse) {
  //   return <h3>Course not found</h3>;
  // }

  return (
    <div
      style={{
        padding: 'var(--space-sm)'
      }}
      className="course-wrapper ">
      {!selectedCourse ? (
        <Loader />
      ) : (
        <div className="course-outer-div flex-container direction-column align-center">
          <h2>{selectedCourse.course.name}</h2>
          <div className="course-inner-div margin-top--lg">
            <CourseNavigation
              id={id}
              activeComponent={activeComponent}
              setActiveComponent={setActiveComponent}
              visitedPages={visitedPages}
              setVisitedPages={setVisitedPages}
            />
            {activeComponent === 'assessment' ? (
              <Assessment selectedCourse={selectedCourse} setVisitedPages={setVisitedPages} />
            ) : activeComponent === 'pdf' ? (
              <PdfPage selectedCourse={selectedCourse} setVisitedPages={setVisitedPages} />
            ) : activeComponent === 'quiz' ? (
              <QuizPage selectedCourse={selectedCourse} setVisitedPages={setVisitedPages} />
            ) : (
              <Assignment
                visitedPages={visitedPages}
                selectedCourse={selectedCourse}
                setVisitedPages={setVisitedPages}
              />
            )}
          </div>
        </div>
      )}
      <style jsx>
        {`
          .course-wrapper {
            min-height: 87vh;
          }
          .course-outer-div {
            margin: auto;
            max-width: 900px;
          }
          .course-inner-div {
            border: 1px solid lightgrey;
            width: 78%;
          }
          @media only screen and (max-width: 600px) {
            .course-inner-div {
              width: 100%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Course;
